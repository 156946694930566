import React, { useState } from "react";
import StringBuilder from "../helper/StringBuilder";
import {
    Alarmanlage,
    Brand,
    Tuerschloss,
    Videoueberwachung,
} from "./PriceCalculator/elements/Constants";
import PriceCalculationButton from "./PriceCalculator/PriceCalculationButton";

const FloorPlan = ({ city }) => {
    const products = {
        1: {
            name: "Glasbruchmelder",
            icon: "/img/elements/floor-plan/danger-white.svg",
            image: "/img/elements/floor-plan/Glasbruchmelder.png",
            description:
                "Dieser Melder erkennt den Bruch (das Einschlagen) von Fensterscheiben. Er wird im Inneren von Wohn- oder Geschäftsgebäuden auf der gegenüberliegenden Wand zu der zu schützenden Scheibe installiert und auf diese gerichtet.",
            system: Alarmanlage,
        },
        2: {
            name: "Bullet-Videokamera",
            icon: "/img/elements/floor-plan/video-camer-white.svg",
            image: "/img/elements/floor-plan/bullet-kamera-dahua-2-unbranded.png",
            description:
                "Erhalten Sie klarste Bilder und erkennen Sie jedes Detail unter allen Umständen! Bullet Kameras bestechen durch eine großräumige Abdeckung und hervorragende Bildauflösung von bis zu 8 Megapixel. Wahlweise auch mit künstlicher Intelligenz ausgestattet erhältlich.",
            system: Videoueberwachung,
        },
        3: {
            name: "Tür-/Fenstersensor",
            icon: "/img/elements/floor-plan/sensor-white.svg",
            image: "/img/elements/floor-plan/Türsensor_anonym.png",
            description:
                "Dieser Melder überwacht Türen und Fenster und löst einen Alarm aus, wenn sie geöffnet werden, während die Alarmanlage scharfgeschaltet ist.",
            system: Alarmanlage,
        },
        4: {
            name: "Innensirene",
            icon: "/img/elements/floor-plan/siren-white.svg",
            image: "/img/elements/floor-plan/Innensirene.png",
            description:
                "Als rein akustischer Signalgeber unterstützt die Innensirene im Alarmfall die Außensirene. Sie alarmiert mit einem Alarmton sowohl den Einbrecher als auch den Benutzer.",
            system: Alarmanlage,
        },
        5: {
            name: "KI-Videokamera",
            icon: "/img/elements/floor-plan/video-camer-white.svg",
            image: "/img/elements/floor-plan/ki-kamera-unbranded.png",
            description:
                "Überlassen Sie die Auswertung und Analyse von Gefahrensituationen komplett Ihren Kameras! Mit künstlicher Intelligenz ausgestattet, können sie Menschen und Fahrzeuge erkennen und identifizieren, sowie eine aktive Abschreckung einleiten. Durch modernste „full-color technology“ werden Aufnahmen selbst in größter Dunkelheit in kräftigen Farben möglich.",
            system: Videoueberwachung,
        },
        6: {
            name: "Bedienteil",
            icon: "/img/elements/floor-plan/keypad-white.svg",
            image: "/img/elements/floor-plan/Bedienteil_anonym.png",
            description:
                "Das Bedienteil bietet Ihnen den perfekten Überblick über den Status Ihrer Alarmanlage. Über das Menü auf dem Display können Sie kinderleicht Ihre Anlage steuern.",
            system: Alarmanlage,
        },
        7: {
            name: "Außensirene",
            icon: "/img/elements/floor-plan/siren-white.svg",
            image: "/img/elements/floor-plan/Außensirene.png",
            description:
                "Die wetterfeste Außensirene warnt alle Personen im Umkreis vor Einbrechern mit einem lauten Warngeräusch. Die optische und akustische Alarmierung (wahlweise auch mit Sprachausgabe erhältlich) wird bequem über die Oberfläche der Zentrale konfiguriert.",
            system: Alarmanlage,
        },
        8: {
            name: "Bewegungsmelder",
            icon: "/img/elements/floor-plan/metal-detector-white.svg",
            image: "/img/elements/floor-plan/Bewegungsmelder_anonym.jpg",
            description:
                "Dieser Melder überwacht Bewegungen in einem Raum. Wenn die Alarmanlage scharfgeschaltet ist und eine Bewegung festgestellt wird, löst der Melder einen Alarm aus.",
            system: Alarmanlage,
        },
        9: {
            name: "Dome-Videokamera",
            icon: "/img/elements/floor-plan/video-camer-white.svg",
            image: "/img/elements/floor-plan/dome-kamera-dahua-unbranded.png",
            description:
                "Behalten Sie den Rundumblick und lassen Sie sich nichts entgehen! Winkel-, Position- und Richtungswechsel sind kein Hindernis. Dome Kameras können sowohl im Innen- als auch im Außenbereich eingesetzt werden. Wahlweise auch mit künstlicher Intelligenz ausgestattet erhältlich.",
            system: Videoueberwachung,
        },
        10: {
            name: "Überfallalarm-/Notrufschalter",
            icon: "/img/elements/floor-plan/siren-white.svg",
            image: "/img/elements/floor-plan/",
            description:
                "Mit Hilfe dieses Schalters ist es möglich, in einer Notsituation (z.B. ein Überfall) einen stillen Alarm auszulösen, der die Polizei informiert, ohne dass der Täter dies mitbekommt.",
            system: Alarmanlage,
        },
        11: {
            name: "Rauch- und Hitzemelder",
            icon: "/img/elements/floor-plan/danger-white.svg",
            image: "/img/elements/floor-plan/Brandmelder_anonym.png",
            description:
                "Dieser Sensor erkennt Rauch oder hohe Temparaturen in einem Raum und warnt Sie mit Hilfe eines akustischen Signals und einer Benachrichtigung auf Ihrem Mobiltelefon. Auch eine Notrufleitstelle kann wahlweise direkt informiert werden.",
            system: Brand,
        },
        12: {
            name: "Elektronisches Türschloss",
            icon: "/img/elements/floor-plan/smart-lock-white.svg",
            image: "/img/elements/floor-plan/Türschloss_anonym.png",
            description:
                "Elektronische Türschlösser bieten zahlreiche Einsatzmöglichkeiten für private Haushalte, aber auch für kleine und mittlere Gewerbeobjekte. Im Zusammenspiel mit den Informationsträgern, wie z.B. Transpondern, Chipkarten oder der App, können Sie die Zugänge Ihrer Besucher oder Mitarbeiter zu Ihren Räumlichkeiten problemlos steuern.",
            system: Tuerschloss,
        },
    };

    const segments = [
        {
            name: "Privat",
            image: "/img/elements/floor-plan/Wohnung.jpg",
            products: [
                {
                    id: 1,
                    top: 0,
                    left: 22,
                },
                {
                    id: 2,
                    top: -3,
                    left: 35,
                },
                {
                    id: 3,
                    top: 1,
                    left: 49,
                },
                {
                    id: 4,
                    top: 23,
                    left: 69,
                },
                {
                    id: 5,
                    top: 22,
                    left: 86,
                },
                {
                    id: 6,
                    top: 52,
                    left: 86,
                },
                {
                    id: 7,
                    top: 69,
                    left: 93,
                },
                {
                    id: 8,
                    top: 72,
                    left: 69,
                },
                {
                    id: 9,
                    top: 54,
                    left: 31,
                },
                // {
                //     id: 10,
                //     top:40,
                //     left:25
                // },
                {
                    id: 11,
                    top: 24,
                    left: 20,
                },
                {
                    id: 12,
                    top: 37,
                    left: 86,
                },
            ],
        },
        {
            name: "Gewerbe",
            image: "/img/elements/floor-plan/Office.jpg",
            products: [
                { id: 11, top: 11, left: 16 },
                {
                    id: 1,
                    top: 49,
                    left: 28,
                },
                {
                    id: 8,
                    top: 62,
                    left: 19,
                },
                {
                    id: 2,
                    top: 75,
                    left: 12,
                },
                {
                    id: 4,
                    top: 75,
                    left: 58,
                },
                {
                    id: 6,
                    top: 64,
                    left: 67,
                },
                {
                    id: 5,
                    top: 76,
                    left: 82,
                },
                {
                    id: 12,
                    top: 66,
                    left: 76,
                },
                {
                    id: 7,
                    top: 51,
                    left: 78,
                },
                {
                    id: 9,
                    top: 13,
                    left: 69,
                },
                {
                    id: 3,
                    top: 49,
                    left: 47,
                },
            ],
        },
    ];

    const [segmentIndex, setSegmentIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalProductId, setModalProductId] = useState(1);

    const handleSegmentChange = (index) => {
        setSegmentIndex(index);
    };

    const openModal = (productId) => {
        setShowModal(true);
        setModalProductId(productId);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const Modal = ({ show, product }) => {
        const stringBuilder = new StringBuilder("city", product.system);
        return (
            <div className={"modal" + (show ? " is-active" : "")}>
                <div
                    onClick={() => closeModal()}
                    className="modal-background"
                ></div>
                <div className="modal-content modal-window">
                    <section className="modal-card-body">
                        <div className="columns is-vcentered">
                            <div className="column m-0">
                                <figure className="image is-square">
                                    <img
                                        src={product.image}
                                        alt={product.name}
                                        className=""
                                    />
                                </figure>
                            </div>
                            <div className="column has-text-left text">
                                <h2 className="title">{product.name}</h2>
                                <p>{product.description}</p>
                            </div>
                        </div>
                        <div className="has-text-centered">
                            <p style={{ marginBottom: "12px" }}>
                                {"Was kostet eine professionelle " +
                                    stringBuilder.getMetaDataProductSingularName() +
                                    "?"}
                            </p>

                            <PriceCalculationButton
                                text="Kostenlos Preis berechnen"
                                city={city ? city.slug : null}
                                product={product.system}
                            />
                        </div>
                    </section>
                </div>
                <button
                    className="modal-close is-large"
                    onClick={() => closeModal()}
                    aria-label="close"
                ></button>
            </div>
        );
    };

    return (
        <div className="floor-plan">
            <h2 className="title tile-custome padding-12px-mob">
                So könnte Ihr Sicherheitskonzept von MeinAlarm24 aussehen
            </h2>
            <Modal show={showModal} product={products[modalProductId]} />
            <div className="segment-control has-text-centered">
                {segments.map((segment, i) => (
                    <span
                        key={i}
                        style={{
                            borderRadius:
                                i === 0 ? "25px 0 0 25px" : "0 25px 25px 0",
                        }}
                        className={
                            "segment-control-btn has-text-weight-medium" +
                            (segmentIndex === i ? " selected" : "")
                        }
                        onClick={() => handleSegmentChange(i)}
                    >
                        {segment.name}
                    </span>
                ))}
            </div>
            <div className="columns is-mobile is-vcentered">
                <div className="column" />
                <div
                    className=" is-narrow parent"
                    style={{ marginBottom: "2rem" }}
                >
                    <img
                        className="image-bg"
                        src={segments[segmentIndex].image}
                    />
                    {segments[segmentIndex].products.map((product, i) => (
                        <div key={i} id={product.id}>
                            <span
                                className="dot"
                                onClick={() => openModal(product.id)}
                                style={{
                                    top: product.top + "%",
                                    left: product.left + "%",
                                }}
                            >
                                <div className="columns m-0">
                                    <img
                                        className="column is-narrow icon"
                                        src={products[product.id].icon}
                                        alt={products[product.id].name}
                                    />
                                    <div className="column text">
                                        <h3>{products[product.id].name}</h3>
                                        <p>
                                            Mehr Informationen &nbsp;{" "}
                                            <i className="fas fa-play" />
                                        </p>
                                    </div>
                                </div>
                            </span>
                        </div>
                    ))}
                </div>
                <div className="column" />
            </div>
        </div>
    );
};

export default FloorPlan;
