import React from "react";

export default class OurPartner extends React.Component {
    render() {
        return (
            <div className="section our-partner">
                <h2 className="title padding-12px-mob">
                    Wir haben starke Partner an unserer Seite
                </h2>
                <div className="columns is-centered is-mobile is-multiline is-vcentered">
                    <div className="column is-2-desktop is-narrow-mobile has-text-centered">
                        <figure className="image is-inline-block">
                            <img
                                src="/img/elements/partners/Daitem_Logo_grey.png"
                                alt="Daitem Logo"
                            />
                        </figure>
                    </div>
                    <div className="column is-2-desktop is-narrow-mobile has-text-centered">
                        <figure className="image is-inline-block">
                            <img
                                src="/img/elements/partners/Jablotron_Logo_grey.png"
                                style={{ padding: "10px" }}
                                alt="Jablotron Logo"
                            />
                        </figure>
                    </div>
                    {/* <div className="column is-2-desktop is-narrow-mobile has-text-centered">
                        <figure className="image is-inline-block">
                            <img
                                src="/img/elements/partners/Dahua_Logo_grey.png"
                                style={{ padding: "5px" }}
                                alt="Dahua Logo"
                            />
                        </figure>
                    </div> */}
                    <div className="column is-2-desktop is-narrow-mobile has-text-centered">
                        <figure className="image is-inline-block">
                            <img
                                src="/img/elements/partners/Ajax-logo-grey.png"
                                className="ajax-logo-custom"
                                style={{ height: "25px" }}
                                alt="Ajax Logo"
                            />
                        </figure>
                    </div>
                    <div className="column is-hidden-mobile is-2-desktop is-narrow-mobile has-text-centered">
                        <figure className="image is-inline-block">
                            <img
                                src="/img/elements/partners/Polizei_Logo_grey.png"
                                style={{ height: "70px" }}
                                alt="Polizei Logo"
                            />
                        </figure>
                    </div>
                    <div className="column is-2-desktop is-narrow-mobile has-text-centered">
                        <figure className="image is-inline-block">
                            <img
                                src="/img/elements/partners/Dahua_Logo_grey.png"
                                style={{ padding: "5px" }}
                                alt="Dahua Logo"
                            />
                        </figure>
                    </div>
                    {/* <div className="column is-2-desktop is-narrow-mobile has-text-centered">
                        <figure className="image is-inline-block">
                            <img
                                src="/img/elements/partners/Jablotron_Logo_grey.png"
                                style={{ padding: "10px" }}
                                alt="Jablotron Logo"
                            />
                        </figure>
                    </div> */}
                    <div className="column is-2-desktop is-narrow-mobile has-text-centered">
                        <figure className="image is-inline-block">
                            <img
                                src="/img/elements/partners/Abus_Logo_grey.png"
                                alt="Abus Logo"
                            />
                        </figure>
                    </div>
                    <div className="column is-hidden-tablet is-narrow">
                        <figure className="image is-inline-block">
                            <img
                                src="/img/elements/partners/Polizei_Logo_grey.png"
                                style={{ height: "70px" }}
                                alt="Polizei Logo"
                            />
                        </figure>
                    </div>
                </div>
            </div>
        );
    }
}
