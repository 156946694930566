import React from "react";
import { Link } from "gatsby";
import { size } from "lodash";
const TickBlue = "/img/homepage/icons8-done-30.png";
function ListItem(props) {
    return (
        <p style={{ marginTop: "12px" }}>
            <img
                src={TickBlue}
                style={{
                    width: "35px",
                    height: "35px",
                    verticalAlign: "middle",
                    display: "block",
                    float: "left",
                }}
            />
            <span
                style={{
                    position: "relative",
                    // top: "-7px",
                    verticalAlign: "middle",
                    left: "5px",

                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    // webkitLineClamp: "3",
                    webkitBoxOrient: "vertical",
                }}
                className="font-pb4"
            >
                <b style={{ marginRight: "2px" }}> {props.text}</b>
            </span>
        </p>
        // <p>
        //     <img src={TickBlue} style={{ width: "26px" }} />
        //     <span
        //         style={{
        //             position: "relative",
        //             top: "-7px",
        //             left: "5px",
        //         }}
        //     >
        //         {props.text}
        //     </span>
        // </p>
    );
}
export default class NewHome2 extends React.Component {
    render() {
        return (
            // <div style={{ backgroundColor: "#F2F2F2" }}>
            <div style={{ backgroundColor: "#F2F2F2", marginTop: "10px" }}>
                <div className="section how-to2 ">
                    <div className="body">
                        <div className="columns">
                            <div className="column has-text-centered">
                                <h2 className="title">
                                    Sicherheit ohne Anschaffungskosten.
                                    Jederzeit finanzierbar.
                                </h2>
                                <h3>
                                    Neben dem klassischen Kauf, bieten wir Ihnen
                                    auch attraktive Finanzierungsoptionen an.
                                </h3>
                            </div>
                        </div>

                        <div>
                            <div className="columns ">
                                <div className="column col-custom-new has-text-centered">
                                    {/* <Link to="/alarmanlagen"> */}
                                    <div
                                        className="card"
                                        style={{
                                            // border: "4px solid #F2F2F2",
                                            border: "4px solid darkgrey",
                                            // backgroundColor: "#F2F2F2",
                                        }}
                                    >
                                        <div
                                            className="card-title "
                                            style={{
                                                // backgroundColor: "#183250",
                                                // backgroundColor: "#F2F2F2",
                                                color: "black",
                                                paddingTop: "18px",
                                                paddingBottom: "18px",

                                                fontSize: "24px",
                                            }}
                                        >
                                            <span className="card-title-main">
                                                {" "}
                                                Miete
                                            </span>
                                            <div>
                                                <small
                                                    style={{ fontSize: "17px" }}
                                                    className="card-title-sec"
                                                >
                                                    Für alle Privatkunden
                                                </small>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                                textAlign: "initial",
                                                marginLeft: "15px",
                                            }}
                                        >
                                            <ListItem text="0,- € Anschaffungskosten" />
                                            <ListItem text="Lebenslange Garantie auf Anlagenkomponenten" />
                                            <ListItem text="Volle Kostenkontrolle für alle Inklusivleistungen" />
                                            <div
                                                style={{ marginBottom: "35px" }}
                                            ></div>
                                        </div>
                                        <div
                                            className="p1custom"
                                            style={{
                                                position: "relative",
                                            }}
                                        >
                                            {/* <p
                                                className="customPa"
                                                style={{
                                                    position: "absolute",
                                                    // marginLeft: "-20px",
                                                    // display: "flex",
                                                    // justifyContent: "center",
                                                    // alignItems: "center",
                                                    color: "F2F2F2",
                                                    fontSize: "20px",
                                                    bottom: "10px",
                                                }}
                                            > */}
                                            {/* Für alle Privatkunden */}
                                            {/* </p> */}
                                        </div>
                                    </div>
                                    {/* </Link> */}
                                </div>
                                <div className="column col-custom-new has-text-centered">
                                    {/* <Link to="/videoueberwachung/"> */}
                                    <div
                                        className="card"
                                        style={{
                                            border: "4px solid darkgrey",
                                        }}
                                    >
                                        <div
                                            className="card-title"
                                            style={{
                                                // backgroundColor: "#183250",
                                                // backgroundColor: "#F2F2F2",
                                                color: "black",
                                                paddingTop: "18px",
                                                paddingBottom: "18px",
                                                fontSize: "24px",
                                            }}
                                        >
                                            <span className="card-title-main">
                                                Leasing
                                            </span>
                                            <div>
                                                <small
                                                    style={{
                                                        fontSize: "17px",
                                                    }}
                                                    className="card-title-sec"
                                                >
                                                    Für alle Gewerbekunden
                                                </small>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                                textAlign: "initial",
                                                marginLeft: "20px",
                                            }}
                                        >
                                            <ListItem text="0,- € Anschaffungskosten" />
                                            <ListItem text="Lebenslange Garantie und Schutz vor Zusatzkosten" />
                                            <ListItem text="Liquiditätsoptimierung und Steuereinsparungen durch 100% Absetzbarkeit" />

                                            <div
                                                style={{ marginBottom: "35px" }}
                                            ></div>
                                            <div
                                                style={{
                                                    position: "relative",
                                                    minHeight: "70px",
                                                }}
                                            >
                                                {/* <p
                                                    className="customPa"
                                                    style={{
                                                        position: "absolute",
                                                        // marginLeft: "-20px",
                                                        // display: "flex",
                                                        // justifyContent: "center",
                                                        // alignItems: "center",
                                                        color: "F2F2F2",
                                                        fontSize: "20px",
                                                        bottom: "10px",
                                                    }}
                                                >
                                                    Für alle Privatkunden
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* </Link>{" "} */}
                                </div>
                                <div className="column col-custom-new has-text-centered">
                                    {/* <Link to="/schliessanlagen/"> */}
                                    <div
                                        className="card"
                                        style={{
                                            border: "4px solid darkgrey",
                                        }}
                                    >
                                        <div
                                            className="card-title"
                                            style={{
                                                // backgroundColor: "#183250",
                                                // backgroundColor: "#F2F2F2",
                                                color: "black",
                                                paddingTop: "18px",
                                                paddingBottom: "18px",
                                                fontSize: "24px",
                                            }}
                                        >
                                            <span className="card-title-main">
                                                Kauf
                                            </span>
                                            <div>
                                                <small
                                                    style={{
                                                        fontSize: "17px",
                                                        color: "black",
                                                    }}
                                                    className="card-title-sec"
                                                >
                                                    Für alle Kunden
                                                </small>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                                textAlign: "initial",
                                                marginLeft: "20px",
                                            }}
                                        >
                                            <ListItem text="Einmalige Anschaffungskosten" />
                                            <ListItem
                                                text="Eigentümer der Sicherheitstechnik werden
"
                                            />
                                        </div>
                                    </div>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
