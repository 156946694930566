import React from "react";
import { Link } from "gatsby";
const TickBlue = "/img/homepage/icons8-done-30.png";
function ListItem(props) {
    return (
        <p style={{ marginTop: "12px" }}>
            <img
                src={TickBlue}
                style={{
                    width: "35px",
                    height: "35px",
                    verticalAlign: "middle",
                    display: "block",
                    float: "left",
                }}
            />
            <span
                style={{
                    position: "relative",
                    // top: "-7px",
                    verticalAlign: "middle",
                    left: "15px",
                    fontSize: "20px",
                    marginRight: "15px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    // webkitLineClamp: "3",
                    webkitBoxOrient: "vertical",
                }}
            >
                <span className="bullet-points"> {props.text}</span>
            </span>
        </p>
    );
}
export default class NewHome3 extends React.Component {
    render() {
        return (
            <div style={{ backgroundColor: "#F2F2F2" }}>
                <div
                    className="section how-to2"
                    // style={{ marginTop: "40px", backgroundColor: "#F2F2F2" }}
                >
                    <div className="body">
                        <div className="columns">
                            <div className="column has-text-centered">
                                <h2 className="title">
                                    {/* Ihr professioneller Partner für Sicherheit.
                                    Ein Leben lang. */}
                                    Wir sind Ihr professioneller Partner für
                                    Sicherheit. Ein Leben lang.
                                </h2>
                                <h3>
                                    Von der Erstberatung bis zur langfristigen
                                    Partnerschaft. Alles aus einer Hand.
                                </h3>
                            </div>
                        </div>

                        <div>
                            <div className="columns">
                                <div className="column has-text-centered">
                                    <div
                                        className="card"
                                        style={{
                                            backgroundColor: "#F2F2F2",
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                                textAlign: "initial",
                                                marginLeft: "20px",
                                            }}
                                        >
                                            <ListItem text="Professionelle Sicherheitsberatung mit maßgeschneiderten Lösungen" />

                                            <ListItem text="Fachgerechte Installation durch unsere zertifizierten Sicherheitstechniker" />
                                            <ListItem text="Hohe Geschwindigkeit vom ersten Kontakt bis zur Inbetriebnahme (in der Regel 7 Tage)" />
                                            <ListItem text="Qualitativ hochwertige Produkte von renommierten Herstellern" />
                                            <ListItem text="Umfassendes Angebot langfristiger Sicherheitsservices" />
                                        </div>
                                        <p style={{ marginTop: "3rem" }}>
                                            <img
                                                style={{
                                                    marginLeft: "10px",
                                                    width: "124.71px",
                                                    height: "65px",
                                                }}
                                                src="/img/homepage/Google-Reviews-Picture.png"
                                            />
                                            <img
                                                style={{
                                                    marginLeft: "20px",
                                                    width: "162.29px",
                                                    height: "65px",
                                                }}
                                                src="/img/homepage/Meisterbetrieb-Picture.png"
                                            />
                                            <img
                                                style={{
                                                    marginLeft: "20px",
                                                    width: "109.54px",
                                                    height: "65px",
                                                }}
                                                src="/img/homepage/Ausgezeichnet.org-Reviews-Picture.png"
                                            />
                                        </p>
                                    </div>
                                </div>
                                <div className="column ">
                                    <div
                                        className="card"
                                        style={{
                                            backgroundColor: "#F2F2F2",
                                        }}
                                    >
                                        <img
                                            src="/img/homepage/USPs-Picture.jpg"
                                            style={{
                                                width: "auto",
                                                height: "auto",
                                                border: "2px solid darkgrey",
                                                borderRadius: "2px",
                                            }}
                                        />

                                        <div className="centerButtonInDiv">
                                            <Link
                                                className="button preisrechner-btn preisrechner-btn-primary newHome3Button"
                                                to="/preisrechner/"
                                                id="btn_USPs_preisberechnen"
                                            >
                                                Preis kostenlos berechnen
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
